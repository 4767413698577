import { number, object } from 'prop-types';
import { CardSmall } from '../../../alimony-calculator/card-small';
import { convertToCurrencyValue, convertDateCompleteWithoutDay } from '../../../../helpers';
import * as styles from './small-cards-group.module.scss';
import calendar from '../assets/calendar.svg';
import earn from '../assets/earn.svg';
import salary from '../assets/salary.svg';
import star from '../assets/star.svg';

function SmallCardsGroup({
  compensation, benefits, hoursIncentive,
  bonus, nextExpectedRaise, addEarningLow, addEarningHigh, leadershipBonus,
}) {
  return (
    <div className={styles.smallCards}>
      <CardSmall icon={calendar}>
        <div className={styles.infoSmallSection}>
          <span className={styles.smallCardTitle}>Core compensation</span>
          <span className={styles.smallCardDesc}>
            Salary:
            {' '}
            {convertToCurrencyValue(compensation)}
          </span>
          <span className={styles.smallCardDesc}>
            Benefits:
            {' '}
            {convertToCurrencyValue(benefits)}
          </span>
        </div>
      </CardSmall>
      <CardSmall icon={earn}>
        <div className={styles.infoSmallSection}>
          <span className={styles.smallCardTitle}>Bonus pay</span>
          <span className={styles.smallCardDesc}>
            Hours:
            {' '}
            {convertToCurrencyValue(hoursIncentive)}
          </span>
          <span className={styles.smallCardDesc}>
            Origination*:
            {' '}
            {convertToCurrencyValue(bonus)}
          </span>
          <span className={styles.smallCardDesc}>
            Time Entry:
            {' '}
            up to $3,900
          </span>
          <span className={styles.smallCardDesc}>
            Leadership:
            {' '}
            {convertToCurrencyValue(leadershipBonus)}
          </span>
        </div>
      </CardSmall>
      <CardSmall icon={star}>
        <div className={styles.infoSmallSection}>
          <span className={styles.smallCardTitle}>Next expected raise</span>
          <span className={styles.smallCardDesc}>
            {convertDateCompleteWithoutDay(nextExpectedRaise)}
          </span>
        </div>
      </CardSmall>
      <CardSmall icon={salary}>
        <div className={styles.infoSmallSection}>
          <span className={styles.smallCardTitle}>
            Potential partner earnings
          </span>
          <span className={styles.smallCardDesc}>
            {convertToCurrencyValue(addEarningLow)}
            {' '}
            to
            {' '}
            {convertToCurrencyValue(addEarningHigh)}
          </span>
        </div>
      </CardSmall>
    </div>
  );
}

SmallCardsGroup.propTypes = {
  compensation: number.isRequired,
  benefits: number.isRequired,
  hoursIncentive: number.isRequired,
  bonus: number.isRequired,
  nextExpectedRaise: object.isRequired,
  addEarningLow: number.isRequired,
  addEarningHigh: number.isRequired,
  leadershipBonus: number.isRequired,
};

export { SmallCardsGroup };
