import { graphql, navigate } from 'gatsby';
import { object } from 'prop-types';
import { useEffect, useState } from 'react';
import { Spinner } from '../../../../../components/spinner';
import { ResetButton } from '../../../../../components/common/reset-button';
import { ButtonLink } from '../../../../../components/common/link';
import { Wrap } from '../../../../../components/sections-wrap';
import { Header, Disclaimer } from '../../../../../components/alimony-calculator';
import { constants } from '../../../../../config';
import { convertToCurrencyValue, calculateYearsPassed, convertDateCompleteWithoutDay } from '../../../../../helpers';
import {
  attorneyPayRedirectToTermsAndConditionIfNecessary,
  attorneyPayRedirectToCalculatorIfNecessary,
  getAllUsefulAttorneyPayValuesFromAnswers,
  getNextExpectedRaise,
  getHoursBonus,
} from '../../../../../functions-calculator';
import { removeItemLocalStorage } from '../../../../../helpers/misc';
import * as styles from './index.module.scss';
import { Card } from '../../../../../components/alimony-calculator/card';
import { SmallCardsGroup } from '../../../../../components/calculator-attorney/payment/small-cards-group';
import estimatedPayment from './assets/estimated-payment.svg';
import { SendResults } from '../../../../../components/alimony-calculator/send-results';

const initializeState = (calculatorTypeName, state) => {
  if (typeof window !== 'undefined' && localStorage[calculatorTypeName]) {
    if (JSON.parse(localStorage[calculatorTypeName])[state]) {
      return (new Map(JSON.parse(localStorage[calculatorTypeName])[state]));
    }
  }
  return new Map();
};

function AlimonyPayment({ data }) {
  const { attorneyPayValues, attorneyConfig, pageSettings } = data;
  const { uri, calculatorTypeDefault } = pageSettings;
  const [isLoaded, setIsLoaded] = useState(true);
  const [answers] = useState(initializeState(calculatorTypeDefault.name, 'answers'));

  useEffect(() => {
    const wasRedirectedToTermsAndConditions = attorneyPayRedirectToTermsAndConditionIfNecessary(
      navigate,
    );
    if (!wasRedirectedToTermsAndConditions) {
      const wasRedirectedToCalculator = attorneyPayRedirectToCalculatorIfNecessary(
        uri,
        calculatorTypeDefault.type,
        navigate,
      );
      if (!wasRedirectedToCalculator) {
        setIsLoaded(false);
      }
    }
  }, []);

  const {
    licenseDate, hoursBilled, casesOriginated, isLeadership,
  } = getAllUsefulAttorneyPayValuesFromAnswers(answers);

  const yearsLicensed = calculateYearsPassed(licenseDate);

  const attorneyPayValue = attorneyPayValues.nodes.find(
    (node) => node.yearsLicensed === (yearsLicensed > 25 ? 25 : yearsLicensed),
  );
  const nextAttorneyPayValue = attorneyPayValues.nodes.find(
    (node) => node.yearsLicensed === (yearsLicensed + 1 > 25 ? 25 : yearsLicensed + 1),
  );
  const { attorneyBasePay, associateAdditionalPay, hourlyRate } = attorneyPayValue || {};

  const { nextAttorneyBasePay, nextAssociateAdditionalPay } = nextAttorneyPayValue || {};

  const coreCompensation = attorneyBasePay + associateAdditionalPay;
  const nextCoreCompensation = nextAttorneyBasePay + nextAssociateAdditionalPay;

  const nextExpectedRaiseDate = getNextExpectedRaise(
    licenseDate,
    coreCompensation,
    nextCoreCompensation,
  );

  const {
    hoursRequirement,
    hourlyBonusPercent,
    benefitValue,
    originationBonusPercent,
    avgOriginatedCaseValue,
    partnerPayLow,
    partnerPayHigh,
    disclaimer,
    orginationBonusDisclaimer,
    highBillerBonusPercent,
    awardsBonus,
  } = attorneyConfig;

  const hoursBonus = getHoursBonus(
    hoursBilled,
    hoursRequirement,
    hourlyRate,
    (parseInt(hourlyBonusPercent, 10) / 100),
    (parseInt(highBillerBonusPercent, 10) / 100),
  );

  const originationBonus = casesOriginated * avgOriginatedCaseValue
  * (parseInt(originationBonusPercent, 10) / 100);

  const minimumTimeEntry = 3900;

  const leadershipBonus = isLeadership ? awardsBonus * 4 : 0;

  const entireCompensation = coreCompensation
  + benefitValue + hoursBonus + originationBonus + minimumTimeEntry + leadershipBonus;

  // As there is just only one calculator type, we don't need to ask in which one we are working on.
  const handleAcceptResetButton = () => {
    removeItemLocalStorage(
      constants.calculatorsIds.attorneyPayCalculator.calculatorFinishedKey,
    );
    removeItemLocalStorage(calculatorTypeDefault.name);
    navigate(`${uri}/${calculatorTypeDefault.type}`);
  };

  const toSendEmailData = {
    estimatedPay: convertToCurrencyValue(entireCompensation),
    salary: convertToCurrencyValue(coreCompensation),
    benefits: convertToCurrencyValue(benefitValue),
    hours: convertToCurrencyValue(hoursBonus),
    origination: convertToCurrencyValue(Math.round(originationBonus)),
    nextRaiseDate: convertDateCompleteWithoutDay(nextExpectedRaiseDate),
  };

  return (
    <div className={styles.pageStyles}>
      <Wrap>
        {isLoaded ? (
          <div className={styles.spinnerContainer}>
            <Spinner className={styles.spinner} />
          </div>
        ) : (
          <div className={styles.calculatorContainer}>
            <Header title={pageSettings.name} />
            <div className={styles.cardContainer}>
              <Card icon={estimatedPayment} backgroundColorCard={styles.backgroundCard}>
                <div className={styles.infoSection}>
                  <p className={styles.title}>Estimated pay</p>
                  <p className={styles.amount}>{convertToCurrencyValue(entireCompensation)}</p>
                </div>
              </Card>
              <SmallCardsGroup
                compensation={coreCompensation}
                benefits={benefitValue}
                hoursIncentive={hoursBonus}
                bonus={Math.round(originationBonus)}
                nextExpectedRaise={nextExpectedRaiseDate}
                addEarningLow={partnerPayLow}
                addEarningHigh={partnerPayHigh}
                leadershipBonus={leadershipBonus}
              />
            </div>
            <ButtonLink
              text="Apply now"
              to="/about/careers/"
              className={styles.button}
            />
            <ResetButton handleAccept={handleAcceptResetButton} />
            <SendResults
              data={toSendEmailData}
              calculatorId={constants.calculatorsIds.attorneyPayCalculator.id}
            />
            {disclaimer?.disclaimer && (
            <Disclaimer
              disclaimer={disclaimer.disclaimer}
              altDisclaimer={orginationBonusDisclaimer}
            />
            )}
          </div>
        )}
      </Wrap>
    </div>
  );
}

export const query = graphql`
  {
    pageSettings: contentfulCalculators(id: {eq: "a7b143ce-704e-5c37-94b1-acae8ac6c4a4"}) {
      uri
      name
      calculatorTypeDefault {
        type
        name
      }
    }
    attorneyPayValues: allContentfulCalculatorAttorneyPayValues {
      nodes {
        id
        associateAdditionalPay
        attorneyBasePay
        hourlyRate
        yearsLicensed
        title
      }
    }
    attorneyConfig: contentfulCalculatorAttorneyConfig(
      id: {eq: "4c94b808-77de-5ec0-9355-ab630d3c4f21"}
    ) {
      id
      benefitValue
      hoursRequirement
      hourlyBonusPercent
      avgOriginatedCaseValue
      originationBonusPercent
      partnerPayHigh
      partnerPayLow
      title
      disclaimer {
        disclaimer
      }
      orginationBonusDisclaimer
      highBillerBonusPercent
      awardsBonus
    }
  }
`;

AlimonyPayment.propTypes = {
  data: object,
};

AlimonyPayment.defaultProps = {
  data: {},
};

export default AlimonyPayment;
export { Head } from '../../../../../components/seo/seo';
