// extracted by mini-css-extract-plugin
export var amount = "index-module--amount--8276b";
export var backgroundCard = "index-module--background-card--8877d";
export var button = "index-module--button--33aef";
export var calculatorContainer = "index-module--calculator-container--a6b7f";
export var cardContainer = "index-module--card-container--d1b52";
export var infoSection = "index-module--info-section--73590";
export var pageStyles = "index-module--page-styles--3bc97";
export var spinner = "index-module--spinner--45038";
export var spinnerContainer = "index-module--spinner-container--c14bc";
export var termsAndConditionsText = "index-module--terms-and-conditions-text--268a7";
export var termsAndConditionsTitle = "index-module--terms-and-conditions-title--cae91";
export var title = "index-module--title--d8705";