import { node, string } from 'prop-types';
import * as styles from './card-small.module.scss';

function CardSmall({ children, icon }) {
  return (
    <div className={styles.card}>
      <img className={styles.imageSize} src={icon} alt="estimated-duration-logo" />
      {children}
    </div>
  );
}

CardSmall.propTypes = {
  children: node.isRequired,
  icon: string.isRequired,
};

export { CardSmall };
