import {
  string, oneOf, node, bool,
} from 'prop-types';
import { Link } from 'gatsby';
import { classNames } from '../../../helpers';
import * as styles from './index.module.scss';

function ButtonLink({
  className, buttonStyle, text, to, startContent, endContent, openAnotherTab,
}) {
  const renderContent = (content) => (<div>{content}</div>);

  return (
    <>
      {openAnotherTab ? (
        <a
          className={classNames([className, styles[buttonStyle]])}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.buttonContent}>
            {startContent && renderContent(startContent)}
            {text && <span>{text}</span>}
            {endContent && renderContent(endContent)}
          </div>
        </a>
      ) : (
        <Link
          className={classNames([className, styles[buttonStyle]])}
          to={to}
        >
          <div className={styles.buttonContent}>
            {startContent && renderContent(startContent)}
            {text && <span>{text}</span>}
            {endContent && renderContent(endContent)}
          </div>
        </Link>
      )}
    </>
  );
}

ButtonLink.propTypes = {
  className: string,
  buttonStyle: oneOf(['primary', 'primaryTextBlue', 'secondary', 'tertiary', 'primaryAlimony', 'secondaryAlimony']),
  text: string.isRequired,
  to: string.isRequired,
  startContent: node,
  endContent: node,
  openAnotherTab: bool,
};

ButtonLink.defaultProps = {
  className: styles.defaultStyle,
  buttonStyle: 'primary',
  startContent: null,
  endContent: null,
  openAnotherTab: false,
};

export { ButtonLink };
